.App {
  margin: auto;
  text-align: left;
  min-width: 300px;
  max-width: 500px;
  padding: 0 10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.todo-item-container {
  border: 1px solid grey;
  padding: 0 20px 10px 10px;
  height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}

.todo-item-container button {
  background-color:
  transparent;
  border: none;
  color:red;
}

.todo-item-container p {
  border: 1px solid grey;
  padding: 20px;
  box-shadow: 2px 2px black;
}

.App-link {
  color: #61dafb;
}
.contact-card {
    margin: 10px auto;
    color: blue;
    border: 1px solid grey;
    min-width: 200px;
    max-width: 300px;
    padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
